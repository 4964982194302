import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/main.css'
import './css/common.css'
import './css/payment.css'
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';

function Dashboard() {
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [amount, setAmount] = useState([1000000, 50000])

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])
    const [reload, setReload] = useState(false)

    let dispatch = useDispatch()

    useEffect(() => {
        dispatch(change_Current_Location("대시보드"))
        dispatch(changeHeaderTitle({
            mainTitle: "대시보드",
            subTitle: ""
        }))
    }, [])

    const [formData, setFormData] = useState({
        MID: 'kpay51000m',
        moid: '',
        orderSeqAutoGenFlg: true,
        Amt: '',
        BuyerName: '',
        GoodsName: '',
        BuyerHpTel1: '',
        BuyerHpTel2: '',
        BuyerHpTel3: '',
        card_num1: '',
        card_num2: '',
        card_num3: '',
        card_num4: '',
        CardPw: '',
        ExpMonth: '',
        ExpYear: '',
        card_gubun: '개인',
        IDNo: '',
        CardQuota: '00',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // 기간 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        pickerRef.current = new Litepicker({
            element: inputRef.current,
            lang: "ko",
            singleMode: false, // 날짜 범위 선택
            format: 'YYYY/MM/DD', // 날짜 형식
            numberOfMonths: 2, // 두 달 표시
            numberOfColumns: 2, // 두 열로 나누어 표시
            showWeekNumbers: true, // 주 번호 표시
            dropdowns: {
                minYear: 2020,
                maxYear: 2030,
                months: true,
                years: true,
            },
        });

        return () => {
            pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
        };
    }, []);

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        pickerRef.current?.setDateRange(startDate, endDate);
    };


    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "이름", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "TID", "width": "120px", "textAlign": "left" },
        { "no": 4, "text": "구분", "width": "180px", "textAlign": "left" },
        { "no": 5, "text": "주민등록번호", "width": "180px", "textAlign": "center" },
        { "no": 6, "text": "사업자구분", "width": "180px", "textAlign": "center" },
        { "no": 7, "text": "핸드폰", "width": "180px", "textAlign": "center" },
        { "no": 8, "text": "이메일", "width": "180px", "textAlign": "center" },
        { "no": 9, "text": "은행명", "width": "180px", "textAlign": "right" },
        { "no": 10, "text": "계좌번호", "width": "180px", "textAlign": "center" },
        { "no": 11, "text": "계좌주", "width": "120px", "textAlign": "center" },
        { "no": 12, "text": "메시지", "width": "300px", "textAlign": "center" }
    ];
    
    const onSubmitSugi = async () => {
        await axios.post(process.env.REACT_APP_API + "/cp/sugi/payment", {
            itemName: formData.GoodsName,
            amount: formData.Amt,
            userName: formData.BuyerName,
            billInfo: formData.card_num1 + formData.card_num2 + formData.card_num3 + formData.card_num4,
            expirePeriod: formData.ExpYear + formData.ExpMonth,
            quota: formData.CardQuota,
            userPhone: formData.BuyerHpTel1 + formData.BuyerHpTel2 + formData.BuyerHpTel3
        }, {
            withCredentials: true,
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setFormData({
                    MID: 'kpay51000m',
                    moid: '',
                    orderSeqAutoGenFlg: true,
                    Amt: '',
                    BuyerName: '',
                    GoodsName: '',
                    BuyerHpTel1: '',
                    BuyerHpTel2: '',
                    BuyerHpTel3: '',
                    card_num1: '',
                    card_num2: '',
                    card_num3: '',
                    card_num4: '',
                    CardPw: '',
                    ExpMonth: '',
                    ExpYear: '',
                    card_gubun: '개인',
                    IDNo: '',
                    CardQuota: '00',
                });
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/cp/sugi/list", {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                tableBodyData.push([
                    { "no": 1, "text": maxFirstPage * (page - 1) + i + 1, "width": "80px", "textAlign": "left" },
                    { "no": 2, "text": data.itemName, "width": "120px", "textAlign": "center" },
                    { "no": 3, "text": data.catId, "width": "120px", "textAlign": "left" },
                    { "no": 4, "text": data.txType, "width": "180px", "textAlign": "left" },
                    { "no": 5, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 6, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 7, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 8, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 9, "text": "-", "width": "180px", "textAlign": "right" },
                    { "no": 10, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 11, "text": "-", "width": "120px", "textAlign": "center" },
                    { "no": 12, "text": "-", "width": "300px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])

    return (
        <div className="flex overflow-hidden" >
            <div class="content" id="contents">
                <div class="payment">
                    <div class="inner-wrap h-full">
                        <div class="content-wrap h-full" style={{ padding: "0" }}>
                            <form className="content-item payForm active" name="payForm" id="payForm" method="post" acceptCharset="UTF-8" autoComplete="off">
                                <div className="intro-y box">
                                    <h2 className="title w-full">결제하기</h2>
                                    <input type="hidden" value={formData.MID} id="MID" name="MID" />

                                    <div className="input hidden">
                                        <label htmlFor="regular-form-3" className="form-label">주문번호</label>
                                        <input
                                            id="moid"
                                            name="moid"
                                            type="text"
                                            className="form-control"
                                            placeholder="주문번호 입력"
                                            value={formData.moid}
                                            onChange={handleChange}
                                        />
                                        <span className="pos_aps_r" style={{ float: 'right' }}>
                                            <input
                                                type="checkbox"
                                                name="orderSeqAutoGenFlg"
                                                id="orderSeqAutoGenFlg"
                                                checked={formData.orderSeqAutoGenFlg}
                                                onChange={handleChange}
                                            />
                                            &nbsp;자동생성
                                        </span>
                                    </div>

                                    <div className="input">
                                        <label htmlFor="Amt" className="form-label">결제요청금액</label>
                                        <input
                                            id="Amt"
                                            name="Amt"
                                            type="number"
                                            className="form-control"
                                            placeholder="금액 입력"
                                            maxLength="9"
                                            value={formData.Amt}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="input">
                                        <label htmlFor="BuyerName" className="form-label">구매자명</label>
                                        <input
                                            id="BuyerName"
                                            name="BuyerName"
                                            type="text"
                                            className="form-control"
                                            placeholder="이름 입력"
                                            maxLength="20"
                                            value={formData.BuyerName}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="input">
                                        <label htmlFor="GoodsName" className="form-label">상품명</label>
                                        <input
                                            id="GoodsName"
                                            name="GoodsName"
                                            type="text"
                                            className="form-control"
                                            placeholder="상품 입력"
                                            maxLength="20"
                                            value={formData.GoodsName}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="input">
                                        <label htmlFor="BuyerHpTel1" className="form-label">휴대폰번호</label>
                                        <div className="multiple">
                                            <input
                                                id="BuyerHpTel1"
                                                name="BuyerHpTel1"
                                                type="tel"
                                                className="form-control"
                                                placeholder="010"
                                                maxLength="3"
                                                value={formData.BuyerHpTel1}
                                                onChange={handleChange}
                                            />
                                            <span>-</span>
                                            <input
                                                id="BuyerHpTel2"
                                                name="BuyerHpTel2"
                                                type="tel"
                                                className="form-control"
                                                placeholder="0000"
                                                maxLength="4"
                                                value={formData.BuyerHpTel2}
                                                onChange={handleChange}
                                            />
                                            <span>-</span>
                                            <input
                                                id="BuyerHpTel3"
                                                name="BuyerHpTel3"
                                                type="tel"
                                                className="form-control"
                                                placeholder="0000"
                                                maxLength="4"
                                                value={formData.BuyerHpTel3}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="input_w_70 input">
                                        <label htmlFor="card_num1" className="form-label">신용카드번호</label>
                                        <div className="multiple">
                                            <input
                                                name="card_num1"
                                                id="card_num1"
                                                type="number"
                                                className="form-control card_num_set"
                                                maxLength="4"
                                                value={formData.card_num1}
                                                onChange={handleChange}
                                            />
                                            <span>-</span>
                                            <input
                                                name="card_num2"
                                                id="card_num2"
                                                type="number"
                                                className="form-control card_num_set"
                                                maxLength="4"
                                                value={formData.card_num2}
                                                onChange={handleChange}
                                            />
                                            <span>-</span>
                                            <input
                                                name="card_num3"
                                                id="card_num3"
                                                type="number"
                                                className="form-control card_num_set"
                                                maxLength="4"
                                                value={formData.card_num3}
                                                onChange={handleChange}
                                            />
                                            <span>-</span>
                                            <input
                                                name="card_num4"
                                                id="card_num4"
                                                type="number"
                                                className="form-control card_num_set"
                                                maxLength="4"
                                                value={formData.card_num4}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="input_w_30 input">
                                        <label htmlFor="CardPw" className="form-label">카드 비밀번호</label>
                                        <input
                                            id="CardPw"
                                            name="CardPw"
                                            type="password"
                                            className="form-control"
                                            maxLength="2"
                                            placeholder="앞 2자리"
                                            value={formData.CardPw}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="input_w_55 input">
                                        <label htmlFor="ExpMonth" className="form-label">카드 유효기간 (월/년)</label>
                                        <div className="multiple">
                                            <input
                                                id="ExpMonth"
                                                name="ExpMonth"
                                                type="number"
                                                className="form-control"
                                                maxLength="2"
                                                placeholder="month"
                                                value={formData.ExpMonth}
                                                onChange={handleChange}
                                            />
                                            <span>/</span>
                                            <input
                                                id="ExpYear"
                                                name="ExpYear"
                                                type="number"
                                                className="form-control"
                                                maxLength="2"
                                                placeholder="year"
                                                value={formData.ExpYear}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="input_w_50 input">
                                        <label htmlFor="card_gubun" className="form-label">개인/법인</label>
                                        <select
                                            id="card_gubun"
                                            name="card_gubun"
                                            className="form-select mt-2 sm:mr-2"
                                            value={formData.card_gubun}
                                            onChange={handleChange}
                                        >
                                            <option value="개인">개인</option>
                                            <option value="법인">법인</option>
                                        </select>
                                    </div>

                                    <div className="input_w_50 input">
                                        <label htmlFor="IDNo" className="form-label">생년월일 6자리</label>
                                        <input
                                            id="IDNo"
                                            name="IDNo"
                                            type="number"
                                            className="form-control"
                                            maxLength="6"
                                            placeholder="생년월일 6자리"
                                            value={formData.IDNo}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="input_w_50 input">
                                        <label htmlFor="CardQuota" className="form-label">할부기간</label>
                                        <select
                                            id="CardQuota"
                                            name="CardQuota"
                                            className="form-select mt-2 sm:mr-2"
                                            value={formData.CardQuota}
                                            onChange={handleChange}
                                        >
                                            <option value="00">일시불</option>
                                            <option value="01">01개월</option>
                                            <option value="02">02개월</option>
                                            <option value="03">03개월</option>
                                            <option value="04">04개월</option>
                                            <option value="05">05개월</option>
                                            <option value="06">06개월</option>
                                        </select>
                                    </div>

                                    <div className="payBt">
                                        <input id="ec_pay_btn" className="btn btn-primary mt-3" value="요청" onClick={() => onSubmitSugi()} />
                                    </div>
                                </div>
                            </form>

                            <div class="content-item intro-y box p-2 mt-5 cardInfo overflow-hidden">
                                <h2 class="title">수기 결제 내역</h2>
                                <div class=" mb-4">
                                    <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                                        <div class="form_filter w-full">
                                            <SearchDate type={"날짜"} inputRef={inputRef} setDateRange={setDateRange} />
                                            <button id="tabulator-html-filter-go" name="searchPayBtn" type="button" class="btn btn-primary  searchPayBtn" onClick={() => setReload(e => !e)}>검색</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="tabulator_summary_wrap">
                                    <TotalAmount confirm_amt={amount[0]} canceled_amt={amount[1]} />
                                </div>

                                <List tableHeaderData={tableHeaderData} tableBodyData={listData?.total ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;