import { changeNum } from '../../common/function';
import '../../css/app.css'
import '../../css/main.css'
import '../../css/common.css'
import '../../css/payment.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"

function DownloadBtn({ text, onClick }) {
    return (
        <div className="excel_download" onClick={onClick}>
            <button className="dropdown-toggle btn btn-outline-secondary" id="paytable1" aria-expanded="true">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="download" data-lucide="download" className="lucide lucide-download">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                </svg>
                {text}
            </button>
        </div>
    )
}

function CountSelect({ onChange }) {
    return (
        <div className="rows_per_page">
            <select id="page_size" className="form-select select_status" onChange={onChange}>
                <option value="10">10개씩 보기</option>
                <option value="30">30개씩 보기</option>
                <option value="50">50개씩 보기</option>
                <option value="100">100개씩 보기</option>
            </select>
        </div>
    )
}

function TotalAmount({ confirm_amt, canceled_amt }) {
    return (
        <>
            <div className="input_wrap" style={{ background: "#d7e3cf" }}>
                <label>승인금액 :</label>
                <input id="confirm_amt" type="text" className="form-control" value={changeNum(confirm_amt)} readonly="" disabled="" />
                {/* <button type="button" className="btn_more">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                </button> */}
            </div>
            <div className="input_wrap" style={{ background: "#fcd9d6" }}>
                <label>취소금액 :</label>
                <input id="canceled_amt" type="text" className="form-control" value={changeNum(canceled_amt)} readonly="" disabled="" />
            </div>
            <div className="input_wrap" style={{ background: "#d2e4fb" }}>
                <label>합계 :</label>
                <input id="tot_amt" type="text" className="form-control" value={changeNum(confirm_amt - canceled_amt)} readonly="" disabled="" />
            </div>
        </>
    )
}

function CountAmount({ tableBodyData }) {
    let len = 0
    if (Array.isArray(tableBodyData)) {
        len = tableBodyData.length;
    } else {
        len = tableBodyData;
    }
    return (
        <div className="tabulator_total_cnt_wrap">
            <input id="total_cnt" type="text" value={`${len}건`} readonly="" />
        </div>
    )
}

function CountAmountLeft({ tableBodyData }) {
    let len = 0
    if (Array.isArray(tableBodyData)) {
        len = tableBodyData.length;
    } else {
        len = tableBodyData;
    }
    return (
        <div class="excel_download">
            <div class="tabulator_total_cnt_wrap">
                <input id="total_cnt" type="text" value={`총 ${len}건`} readonly="" />
            </div>
        </div>
    )
}

export { DownloadBtn, CountSelect, TotalAmount, CountAmount, CountAmountLeft }