import './App.css';
import './Pages/css/main.css'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Header from './Pages/nav/header';
import Footer from './Pages/nav/footer';
import Sidebar from './Pages/nav/sidebar';

import Login from './Pages/login';
import Dashboard from './Pages/dashboard';
import Paylist from './Pages/paylist';
import Notice from './Pages/notice';

import ErrorBoundary from './Pages/common/ErrorBoundary'
import { useEffect, useState } from 'react';

function App() {
  console.error = (message) => {
    // if (message.startsWith('Warning: ...')) {
    //   return; // 특정 경고 무시
    // }
    // originalConsoleError(message);
  };

  let location = useLocation();

  return (
    <div className="App">
      <div className="py-5 md:py-0 header">
        <Header />
        <div className='section1'>
          <Sidebar />
          <div className={`section2 ${location.pathname == '/' ? "main" : ""}`}>
            <ErrorBoundary>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/paylist" element={<Paylist />} />
                <Route path="/notice" element={<Notice />} />

                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </ErrorBoundary>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
