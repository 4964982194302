import '../../css/app.css'
import '../../css/main.css'
import '../../css/common.css'
import '../../css/payment.css'
import Page from '../../Page';
import React from 'react';
import { useState, useEffect, useRef } from 'react';

function List({ tableHeaderData, tableBodyData, filter_tableBodyData, page, setPage, maxFirstPage }) {
    let widthRef = useRef()
    const [Width, setWidth] = useState("0")
    const [tableWidth, setTableWidth] = useState("0")
    const [extraWidth, setExtraWidth] = useState(0)
    const [Window, setWindow] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            setWindow(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setWidth(`${widthRef?.current?.offsetWidth}` ?? "100%")
    }, [Window])

    useEffect(() => {
        if (Width != "0" && tableWidth != "0" && !isNaN(Width) && !isNaN(tableWidth)) {
            if (Number(Width) > Number(tableWidth)) {
                setExtraWidth((Number(Width) - Number(tableWidth)) / (tableHeaderData.length))
            }
            else {
                setExtraWidth(0)
            }
        }
    }, [Width, tableWidth])

    return (
        <div className="overflow-x-auto scrollbar-hidden">
            <div id="tabulator2" className="mt-3 table-report table-report--tabulator payList tabulator" role="grid" tabulator-layout="fitColumns">
                <div className='tabulator-tableHolder' ref={widthRef}>
                    <Listheader tableHeaderData={tableHeaderData} Width={Width} extraWidth={extraWidth} tableWidth={tableWidth} setTableWidth={setTableWidth} />
                    <Listbody filter_tableBodyData={filter_tableBodyData} extraWidth={extraWidth} />
                </div>
                <Page initialData={tableBodyData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
            </div>
        </div>
    )
}

function Listheader({ tableHeaderData, Width, extraWidth, tableWidth, setTableWidth }) {
    let ref = useRef()

    useEffect(() => {
        setTableWidth(`${ref?.current?.offsetWidth}` ?? "100%")
    }, [])

    return (
        <div className="tabulator-header" tabindex="0" style={{ paddingRight: "0px", marginLeft: "0px", width: `${Number(Width) > Number(tableWidth) ? Width : tableWidth}px` }}>
            <div className="tabulator-headers tabulator-table" style={{ marginLeft: "0px" }} ref={ref}>
                {
                    tableHeaderData.map(function (a, i) {
                        return (
                            <TableHeaderList key={i} item={a} extraWidth={extraWidth} />
                        )
                    })
                }
            </div>
            <div className="tabulator-frozen-rows-holder"></div>
        </div>
    )
}

function Listbody({ filter_tableBodyData, extraWidth }) {

    return (
        <div className="" tabindex="0" style={{ minHeight: " calc(100% - 84px)", height: "auto", maxHeight: "calc(100% - 84px)" }}>
            <div className="tabulator-table" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                {
                    filter_tableBodyData.map((row, rowIndex) => (
                        <div className="tabulator-row tabulator-selectable tabulator-row-odd" role="row" style={{ paddingLeft: "0px", backgroundColor: rowIndex % 2 === 1 ? "rgb(241 245 249 / 1)" : "#fff" }}>
                            {
                                row.map((item, itemIndex) => (
                                    <TableBodyList key={`${rowIndex}-${itemIndex}`} item={item} extraWidth={extraWidth} />
                                ))
                            }
                            <div className="tabulator-responsive-collapse"></div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

function TableHeaderList({ item, extraWidth }) {
    let wid = item?.width?.split('px')
    return (
        <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" style={{ minWidth: `${Number(wid[0]) + extraWidth}px` ?? "100%", width: `${Number(wid[0]) + extraWidth}px` ?? "100%", height: "44px" }}>
            <div className="tabulator-col-content">
                <div className="tabulator-col-title-holder">
                    <div className="tabulator-col-title" style={{ textAlign: item?.textAlign ?? "center" }}>{item.text}</div>
                    {/* <div className="tabulator-col-sorter"><div className="tabulator-arrow"></div></div> */}
                </div>
            </div>
            <div className="tabulator-col-resize-handle"></div>
            <div className="tabulator-col-resize-handle prev"></div>
        </div>
    )
}

function TableBodyList({ item, extraWidth }) {
    let wid = item?.width?.split('px')
    return (
        <div className="tabulator-cell" role="gridcell" style={{ width: `${Number(wid[0]) + extraWidth}px` ?? "100%", textAlign: item?.textAlign ?? "center", display: "inline-flex", alignItems: "flex-start", justifyContent: item.textAlign == "left" ? "flex-start" : item.textAlign == "center" ? "center" : item.textAlign == "right" ? "flex-end" : "center", height: "36px" }}>
            {item.text}
            <div className="tabulator-col-resize-handle"></div>
            <div className="tabulator-col-resize-handle prev"></div>
        </div>
    )
}

export default List;