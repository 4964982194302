import '../../css/app.css'
import '../../css/main.css'
import '../../css/common.css'
import '../../css/payment.css'
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';

function SearchDate({ type, inputRef, setDateRange }) {
    // 버튼 클릭 핸들러
    const handleTodayClick = () => {
        const today = dayjs().format('YYYY/MM/DD');
        setDateRange(today, today);
    };

    const handleYesterdayClick = () => {
        const yesterday = dayjs().subtract(1, 'day').format('YYYY/MM/DD');
        setDateRange(yesterday, yesterday);
    };

    const handleWeekClick = () => {
        const today = dayjs().format('YYYY/MM/DD');
        const oneWeekAgo = dayjs().subtract(7, 'days').format('YYYY/MM/DD');
        setDateRange(oneWeekAgo, today);
    };

    const handleThisMonthClick = () => {
        const startOfMonth = dayjs().startOf('month').format('YYYY/MM/DD');
        const today = dayjs().format('YYYY/MM/DD');
        setDateRange(startOfMonth, today);
    };

    const handleLastMonthClick = () => {
        const startOfLastMonth = dayjs().subtract(1, 'month').startOf('month').format('YYYY/MM/DD');
        const endOfLastMonth = dayjs().subtract(1, 'month').endOf('month').format('YYYY/MM/DD');
        setDateRange(startOfLastMonth, endOfLastMonth);
    };

    return (
        <div class="form_filter_inner">
            <label>{type}</label>
            <div class="">
                <input ref={inputRef} type="text" id="datepicker1" name="datepicker1" data-daterange="true" class="datepicker form-control" placeholder="날짜 범위를 선택하세요" />
                <div class="sorting flex">
                    <button id="today_pay_btn" name="today_pay_btn" type="button" class="btn sm:w-20 searchPayBtn" onClick={handleTodayClick}>당일</button>
                    <button id="before_pay_btn" name="before_pay_btn" type="button" class="btn sm:w-20 searchPayBtn" onClick={handleYesterdayClick}>전일</button>
                    <button id="weeks_pay_btn" name="weeks_pay_btn" type="button" class="btn sm:w-20 searchPayBtn" onClick={handleWeekClick}>1주일</button>
                    <button id="months_pay_btn" name="months_pay_btn" type="button" class="btn sm:w-20 searchPayBtn" onClick={handleThisMonthClick}>당월</button>
                    <button id="before_months_pay_btn" name="before_months_pay_btn" type="button" class="btn sm:w-20 searchPayBtn" onClick={handleLastMonthClick}>전월</button>
                </div>
            </div>
        </div>
    )
}

function SearchSelectText({ type, selectData, handleSelectChange, selectedValue, handleSelectTextChange, selectedText }) {
    return (
        <div className="input_row">
            <label>{type}</label>
            <div className="input_wrap">
                <div className="input_item flex">
                    <select id="check_gubun" name="check_gubun" className="form-select" aria-label="Default select example"
                        onChange={handleSelectChange} value={selectedValue}>
                        {
                            selectData.map(function (a, i) {
                                return (
                                    <option value={a}>{a}</option>
                                )
                            })
                        }
                    </select>
                    <input id="level_info" type="text" className="form-control" placeholder="영업자명을 정확하게 입력해주십시오" maxlength="60" disabled="" value={selectedText} onChange={handleSelectTextChange} />
                </div>
            </div>
        </div>
    )
}

function SearchSelect({ type, selectData, handleSelectChange, selectedValue }) {
    return (
        <div className="input_row flex">
            <label>{type}</label>
            <div className="input_wrap">
                <div className="input_item">
                    <select id="select_status" className="form-select" onChange={handleSelectChange} value={selectedValue}>
                        {
                            selectData.map(function (a, i) {
                                return (
                                    <option value={a}>{a}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
        </div>
    )
}

function SearchRange({ type, preRange, handlePreRange, nextRange, handleNextRange }) {
    return (
        <div className="input_row flex amt_range">
            <label>{type}</label>
            <div className="input_wrap">
                <div className="input_item" style={{ display: "flex" }}>
                    <input id="start_amt" type="text" className="form-control" placeholder="0" maxlength={7} value={preRange} onChange={handlePreRange} />
                    <span style={{ margin: "0.5rem" }}> ~ </span>
                    <input id="end_amt" type="text" className="form-control" placeholder="0" maxlength={7} value={nextRange} onChange={handleNextRange} />
                </div>
            </div>
        </div>
    )
}

function SearchInput({ type, selectedText, handleSelectTextChange }) {
    return (
        <div class="input_row">
            <label>{type}</label>
            <div class="input_wrap">
                <div class="input_item flex">
                    <input id="search_value" type="text" className="form-control" placeholder="입력해주세요" maxlength="60" disabled="" value={selectedText} onChange={handleSelectTextChange} />
                </div>
            </div>
        </div>
    )
}

function SearchInputBtn({ type, selectedText, handleSelectTextChange, onClick }) {
    return (
        <div class="input_row">
            <label>{type}</label>
            <div class="input_wrap">
                <div class="input_item flex">
                    <input id="search_value" type="text" className="form-control" placeholder="입력해주세요" maxlength="60" disabled="" value={selectedText} onChange={handleSelectTextChange} />
                    <button onClick={onClick} id="tabulator-html-filter-go" type="button" class="btn btn-primary">검색</button>
                </div>
            </div>
        </div>
    )
}

function SearchBtn({ type, onClick }) {
    return (
        <div className="button_wrap">
            <button onClick={onClick} id="tabulator-html-filter-go" name="searchPayBtn" type="button" className="btn btn-primary searchPayBtn">{type}</button>
        </div>
    )
}

function SearchCrteateBtn({ type, onClick }) {
    return (
        <button type="button" class="btn btn-primary modal_btn btn_small" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" data-lucide="plus" class="lucide lucide-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
            {type}
        </button>
    )
}

function SearchExcelBtn({ type }) {
    return (
        <button type="button" class="btn btn-primary on-pc" id="dist_excel_down" aria-expanded="true">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="download" data-lucide="download" class="lucide lucide-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
            {type}
        </button>
    )
}

export { SearchDate, SearchSelectText, SearchSelect, SearchRange, SearchBtn, SearchCrteateBtn, SearchExcelBtn, SearchInputBtn, SearchInput }