import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/main.css'
import './css/common.css'
import './css/payment.css'
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';

function Paylist() {
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [amount, setAmount] = useState([1000000, 50000])

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])
    const [reload, setReload] = useState(false)

    let dispatch = useDispatch()

    useEffect(() => {
        dispatch(change_Current_Location("거래조회"))
        dispatch(changeHeaderTitle({
            mainTitle: "거래조회",
            subTitle: ""
        }))
    }, [])

    const [formData, setFormData] = useState({
        MID: 'kpay51000m',
        moid: '',
        orderSeqAutoGenFlg: true,
        Amt: '',
        BuyerName: '',
        GoodsName: '',
        BuyerHpTel1: '',
        BuyerHpTel2: '',
        BuyerHpTel3: '',
        card_num1: '',
        card_num2: '',
        card_num3: '',
        card_num4: '',
        CardPw: '',
        ExpMonth: '',
        ExpYear: '',
        card_gubun: '개인',
        IDNo: '',
        CardQuota: '00',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // 기간 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        pickerRef.current = new Litepicker({
            element: inputRef.current,
            lang: "ko",
            singleMode: false, // 날짜 범위 선택
            format: 'YYYY/MM/DD', // 날짜 형식
            numberOfMonths: 2, // 두 달 표시
            numberOfColumns: 2, // 두 열로 나누어 표시
            showWeekNumbers: true, // 주 번호 표시
            dropdowns: {
                minYear: 2020,
                maxYear: 2030,
                months: true,
                years: true,
            },
        });

        return () => {
            pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
        };
    }, []);

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        pickerRef.current?.setDateRange(startDate, endDate);
    };


    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "이름", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "TID", "width": "120px", "textAlign": "left" },
        { "no": 4, "text": "구분", "width": "180px", "textAlign": "left" },
        { "no": 5, "text": "주민등록번호", "width": "180px", "textAlign": "center" },
        { "no": 6, "text": "사업자구분", "width": "180px", "textAlign": "center" },
        { "no": 7, "text": "핸드폰", "width": "180px", "textAlign": "center" },
        { "no": 8, "text": "이메일", "width": "180px", "textAlign": "center" },
        { "no": 9, "text": "은행명", "width": "180px", "textAlign": "right" },
        { "no": 10, "text": "계좌번호", "width": "180px", "textAlign": "center" },
        { "no": 11, "text": "계좌주", "width": "120px", "textAlign": "center" },
        { "no": 12, "text": "메시지", "width": "300px", "textAlign": "center" }
    ];

    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/cp/transaction/list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage,
                startDate: "",
                endDate: "",
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData?.items ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                let date = data.createdAt.split('T')
                let date1 = date[1].split('.')

                tableBodyData.push([
                    { "no": 1, "text": maxFirstPage * (page - 1) + i + 1, "width": "80px", "textAlign": "left" },
                    { "no": 2, "text": data.itemName, "width": "120px", "textAlign": "center" },
                    { "no": 3, "text": data.catId, "width": "120px", "textAlign": "left" },
                    { "no": 4, "text": data.franchisee.name, "width": "180px", "textAlign": "left" },
                    { "no": 5, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 6, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 7, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 8, "text": "-", "width": "180px", "textAlign": "center" },
                    { "no": 9, "text": data.cardName, "width": "180px", "textAlign": "right" },
                    { "no": 10, "text": data.cardNo, "width": "180px", "textAlign": "center" },
                    { "no": 11, "text": "-", "width": "120px", "textAlign": "center" },
                    { "no": 12, "text": data.returnMsg, "width": "300px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])

    return (
        <div class="content" id="contents">
            <div class="paylist">
                <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                    <div class="form_filter w-full">
                        <SearchDate type={"날짜"} inputRef={inputRef} setDateRange={setDateRange} />
                        <button id="tabulator-html-filter-go" name="searchPayBtn" type="button" class="btn btn-primary  searchPayBtn" onClick={() => setReload(e => !e)}>검색</button>
                    </div>
                </form>
                <div class="intro-y box mt-5 md:p-2 sm:p-0">
                    <div className="tabulator_summary_wrap item_wrap">
                        <TotalAmount confirm_amt={amount[0]} canceled_amt={amount[1]} />
                    </div>
                    <List tableHeaderData={tableHeaderData} tableBodyData={listData?.total ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
                </div>
            </div>
        </div>
    )
}

export default Paylist;