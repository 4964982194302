import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/main.css'
import './css/common.css'
import './css/payment.css'
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';

function Notice() {
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [amount, setAmount] = useState([1000000, 50000])

    let dispatch = useDispatch()

    useEffect(() => {
        dispatch(change_Current_Location("공지사항"))
        dispatch(changeHeaderTitle({
            mainTitle: "공지사항",
            subTitle: ""
        }))
    }, [])

    const [formData, setFormData] = useState({
        MID: 'kpay51000m',
        moid: '',
        orderSeqAutoGenFlg: true,
        Amt: '',
        BuyerName: '',
        GoodsName: '',
        BuyerHpTel1: '',
        BuyerHpTel2: '',
        BuyerHpTel3: '',
        card_num1: '',
        card_num2: '',
        card_num3: '',
        card_num4: '',
        CardPw: '',
        ExpMonth: '',
        ExpYear: '',
        card_gubun: '개인',
        IDNo: '',
        CardQuota: '00',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };


    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "이름", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "TID", "width": "120px", "textAlign": "left" },
        { "no": 4, "text": "구분", "width": "180px", "textAlign": "left" },
        { "no": 5, "text": "주민등록번호", "width": "180px", "textAlign": "center" },
        { "no": 6, "text": "사업자구분", "width": "180px", "textAlign": "center" },
        { "no": 7, "text": "핸드폰", "width": "180px", "textAlign": "center" },
        { "no": 8, "text": "이메일", "width": "180px", "textAlign": "center" },
        { "no": 9, "text": "은행명", "width": "180px", "textAlign": "right" },
        { "no": 10, "text": "계좌번호", "width": "180px", "textAlign": "center" },
        { "no": 11, "text": "계좌주", "width": "120px", "textAlign": "center" },
        { "no": 12, "text": "메시지", "width": "300px", "textAlign": "center" }
    ];

    const tableBodyData = [
        { "no": 1, "text": "-", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "-", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "-", "width": "120px", "textAlign": "left" },
        { "no": 4, "text": "-", "width": "180px", "textAlign": "left" },
        { "no": 5, "text": "-", "width": "180px", "textAlign": "center" },
        { "no": 6, "text": "-", "width": "180px", "textAlign": "center" },
        { "no": 7, "text": "-", "width": "180px", "textAlign": "center" },
        { "no": 8, "text": "-", "width": "180px", "textAlign": "center" },
        { "no": 9, "text": "-", "width": "180px", "textAlign": "right" },
        { "no": 10, "text": "-", "width": "180px", "textAlign": "center" },
        { "no": 11, "text": "-", "width": "120px", "textAlign": "center" },
        { "no": 12, "text": "-", "width": "300px", "textAlign": "center" }
    ];

    const tableBodyData1 = [];

    for (let i = 0; i < 5; i++) {
        tableBodyData1.push(tableBodyData)
    }

    // 한 페이지당 보여지는 개수만큼 배열 자르기
    let filter_tableBodyData = tableBodyData1.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)


    return (
        <div class="content" id="contents">
            <div class="notice">
                <form id="tabulator-html-filter-form" class="w-full">
                    <div class="form_filter outer w-full ">
                        <div class="input_item">
                            <label class="mb-2">검색어</label>
                            <input type="text" id="datepicker1" name="datepicker1" data-daterange="true" class="datepicker form-control " />
                        </div>
                        <button id="tabulator-html-filter-go" name="searchPayBtn" type="button" class="btn btn-primary sm:w-20 searchPayBtn">검색</button>
                    </div>
                </form>
                <div class="intro-y box p-5 mt-5">
                    <div class="overflow-x-auto scrollbar-hidden">
                    <List tableHeaderData={tableHeaderData} tableBodyData={tableBodyData1} filter_tableBodyData={filter_tableBodyData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notice;